// Media breakpoints
$screen-xs: 375px; // Very small devices
$screen-sm: 576px; // Small tablets and large smartphones (landscape view)
$screen-md: 768px; // Small tablets (portrait view)
$screen-lg: 992px; // Tablets and small desktops
$screen-xl: 1200px; // Large tablets and desktops

// Custom devices
@mixin media-custom($screen) {
  @media (min-width: #{$screen + 'px'}) {
    @content;
  }
}

@mixin media-portrait-custom($screen) {
  @media (min-height: #{$screen + 'px'}) {
    @content;
  }
}

// Small devices

// 375px window width and more
@mixin media-xs {
  @media (min-width: #{$screen-xs}) {
    @content;
  }
}

// 576px window width and more
@mixin media-sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// 576px window height and more
@mixin media-portrait-sm {
  @media (min-height: #{$screen-sm}) {
    @content;
  }
}

// Medium devices

// 768px window width and more
@mixin media-md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}
// 768px window height and more
@mixin media-portrait-md {
  @media (min-height: #{$screen-md}) {
    @content;
  }
}

// Large devices
// 992px window width and more
@mixin media-lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices

//1200px window width and more
@mixin media-xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
@mixin media-portrait-xl {
  @media (min-height: #{$screen-xl}) {
    @content;
  }
}

@mixin media-portrait-xl {
  @media (min-height: #{$screen-xl}) {
    @content;
  }
}
