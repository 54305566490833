/*
Flaticon icon font: Flaticon
Creation date: 18/07/2016 10:25
*/

@font-face {
  font-family: 'Flaticon';
  src: url('/assets/fonts/Flaticon.eot');
  src: url('/assets/fonts/Flaticon.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Flaticon.woff') format('woff'),
    url('/assets/fonts/Flaticon.ttf') format('truetype'), url('/assets/fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('/assets/fonts/Flaticon.svg#Flaticon') format('svg');
  }
}
.fi {
  display: block;
}
.fi:before {
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-arrows:before {
  content: '\f100';
}

.flaticon-right:before {
  content: '\f101';
}

$font-Flaticon-arrows: '\f100';
$font-Flaticon-right: '\f101';
