.alert-title.sc-ion-alert-md {
  font-size: var(--pos-title-font-size);
}

.alert-sub-title.sc-ion-alert-md {
  font-size: var(--pos-sub-title-font-size);
}

.alert-message.sc-ion-alert-md {
  font-size: var(--pos-text-font-size);
}

.alert-button-inner.sc-ion-alert-md {
  font-size: var(--pos-button-font-size);
}

.alert-input-group.sc-ion-alert-md {
  font-size: var(--pos-input-font-size);
}

.alert-radio-label.sc-ion-alert-md {
  font-size: var(--pos-label-font-size);
}

.alert-checkbox-label.sc-ion-alert-md {
  font-size: var(--pos-button-font-size);
}

