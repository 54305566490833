@use 'mixins/icon.mixin' as *;

.custom-icon {
  background-image: url('/assets/images/sprite.png');
  background-repeat: no-repeat;
  background-size: 275px auto;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

.custom-icon-discount-cash {
  width: 22px;
  height: 22px;
  background-position: -18px -18px;
}

.custom-icon-discount-percent {
  width: 26px;
  height: 24px;
  background-position: -16px -76px;
}

.custom-icon-add-custom-product {
  width: 25px;
  height: 23px;
  background-position: -26px -143px;
}

.custom-icon-add-percent {
  width: 25px;
  height: 25px;
  background-position: -26px -220px;
}

.custom-icon-barcode {
  width: 24px;
  height: 22px;
  background-position: -14px -286px;
}

.custom-icon-bell-filled {
  width: 50px;
  height: 63px;
  background-position: -1.5px -337.5px;
}

.custom-icon-bell {
  width: 50px;
  height: 63px;
  background-position: -1.5px -405.5px;
}

.custom-icon-cancel-search {
  width: 22px;
  height: 22px;
  background-position: -1.5px -456px;
}

.custom-icon-delete-store {
  width: 24px;
  height: 24px;
  background-position: -1px -478px;
}

.custom-icon-edit {
  width: 25px;
  height: 25px;
  background-position: -1.5px -506px;
}

.custom-icon-logout {
  width: 26px;
  height: 22px;
  background-position: -14px -548px;
}

.custom-icon-merge {
  width: 30px;
  height: 26px;
  background-position: -1.5px -586.5px;
}

.custom-icon-more {
  width: 30px;
  height: 8px;
  background-position: -2px -614.5px;
}

.custom-icon-network-error {
  width: 74px;
  height: 74px;
  background-position: -1.5px -648.5px;
}

.custom-icon-no-network {
  width: 30px;
  height: 24px;
  background-position: -12px -720px;
  margin-top: 2px;
}

.custom-icon-paid-invoices-epson {
  width: 19px;
  height: 23px;
  background-position: -1.5px -765.5px;
}

.custom-icon-customer_display {
  width: 23px;
  height: 24px;
  background-position: -42px -765.5px;
}

.custom-icon-paid-invoices-mail {
  width: 25px;
  height: 28px;
  background-position: -1.5px -791.5px;
}

.custom-icon-paid-invoices-printer {
  width: 28px;
  height: 26px;
  background-position: -1.5px -822.5px;
}

.custom-icon-paid-invoices-trash {
  width: 24px;
  height: 24px;
  background-position: -1.5px -851px;
}

.custom-icon-paid-success {
  width: 116px;
  height: 110px;
  background-position: -1.5px -877px;
}

.custom-icon-photo {
  width: 50px;
  height: 38px;
  background-position: -1.5px -990px;
}

.custom-icon-receipt-printer-iphon {
  width: 49px;
  height: 58px;
  background-position: -1.5px -1069.5px;
}

.custom-icon-receipt {
  width: 19px;
  height: 25px;
  background-position: -1.5px -1237px;
}

.custom-icon-scan-mobile {
  width: 20px;
  height: 36px;
  background-position: -2px -1089.5px;
}

.custom-icon-search {
  width: 50px;
  height: 50px;
  background-position: -1.5px -1170.5px;
}

.custom-icon-select {
  width: 34px;
  height: 26px;
  background-position: -1.5px -1180px;
}

.custom-icon-split {
  width: 24px;
  height: 27px;
  background-position: -1.5px -1208px;
}

.custom-icon-testprint {
  width: 17px;
  height: 22px;
  background-position: -1.5px -1284.5px;
}

.custom-icon-webview-refresh {
  width: 25px;
  height: 22px;
  background-position: -1.5px -1270px;
}

.custom-icon-invoice-add-new {
  width: 21px;
  height: 21px;
  background-position: -5px -1348px;
}

.custom-icon-minus-border {
  width: 26px;
  height: 5px;
  background-position: -30px -1351px;
}

.custom-icon-password-help {
  width: 26px;
  height: 25px;
  background-position: -1.5px -1385px;
}

.custom-icon-pay-check {
  width: 33px;
  height: 22px;
  background-position: -1.5px -1466px;
}

.custom-icon-pay-delete {
  width: 28px;
  height: 22px;
  background-position: -1.5px -1438px;
}

.custom-icon-pay-unlock {
  width: 25px;
  height: 34px;
  background-position: -1.5px -1461px;
}

.custom-icon-phone-add-discount-percent {
  width: 19px;
  height: 19px;
  background-position: -1.5px -1554px;
}

.custom-icon-phone-search-customer-barcode {
  width: 21px;
  height: 21px;
  background-position: -1.5px -1578px;
}

.custom-icon-plus-border {
  width: 24px;
  height: 24px;
  background-position: -30px -1564px;
}

.custom-icon-scanner-no-camera-access {
  width: 280px;
  height: 196px;
  background-position: -1.5px -1666px;
}

.custom-icon-setting-big-iphone-5 {
  width: 73px;
  height: 73px;
  background-position: -1.5px -1800px;
}

.custom-icon-table {
  width: 24px;
  height: 14px;
  background-position: 0px -1875px;
}

.custom-icon-trash-border {
  width: 62px;
  height: 52px;
  background-position: -1.5px -1964px;
}

.custom-icon-dummy-customer-border {
  width: 54px;
  height: 54px;
  background: url('/assets/images/dummy-customer-border@2x.png') 0 0 no-repeat;
  background-size: 150px auto;
  background-position: -48px -38px;
}

.custom-icon-socket-scanner {
  width: 30px;
  height: 30px;
  background-position: -1.5px -1543px;
}

.custom-icon-products {
  background: url('/assets/images/icons/illustration@2x.png') center/100% no-repeat;
  width: 100%;
  height: 15rem;
  max-width: 30rem;
  vertical-align: top;
  display: inline-block;
}

.custom-icon-add-product {
  background: url('/assets/images/icons/Add-Product-Icon@2x.png') center/100% no-repeat;
  width: 30px;
  height: 26px;
  vertical-align: middle;
  display: inline-block;
}

.custom-icon-play-video {
  background: url('/assets/images/icons/Play-Video-Tutorial-Icon@2x.png') center/100% no-repeat;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  display: inline-block;
}

.custom-icon-help-center {
  background: url('/assets/images/icons/Visit-Helpcenter-Icon@2x.png') center/100% no-repeat;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  display: inline-block;
}

.custom-icon-add-customer {
  background: url('/assets/images/icons/add-customer-icon@2x.png') center/100% no-repeat;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  display: inline-block;
}

.custom-icon-add-invoice {
  background: url('/assets/images/icons/add-invoice-button-icon@2x.png') center/100% no-repeat;
  width: 30px;
  height: 32px;
  vertical-align: middle;
  display: inline-block;
}

.custom-icon-contact {
  background: url('/assets/images/icons/contact@2x.png') center/100% no-repeat;
  width: 100%;
  height: 15rem;
  max-width: 15rem;
  vertical-align: top;
  display: inline-block;
}

.custom-icon-report {
  background: url('/assets/images/icons/report@2x.png') center/100% no-repeat;
  width: 100%;
  height: 15rem;
  max-width: 15rem;
  vertical-align: top;
  display: inline-block;
}

.custom-icon-sales {
  background: url('/assets/images/icons/sales@2x.png') center/100% no-repeat;
  width: 100%;
  height: 20rem;
  max-width: 15rem;
  vertical-align: top;
  display: inline-block;
}

.bill-icon {
  background: url('/assets/images/icons/icon-bill.svg');
  width: 18px;
  height: 22px;
  display: inline-block;
  margin-top: 3px;
}

.course-fire-icon {
  @include svg-icon('/assets/images/icons/course-fire.svg');
  background-color: var(--pos-light-blue);
  width: 30px;
  height: 20px;
  display: inline-block;
}

.add-individual-product {
  background: url('/assets/images/icons/add-individual.png');
  width: 30px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.new-invoice-icon {
  width: 23px;
  height: 23px;
  background-image: url('/assets/images/icons/invoice.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.notify-new-invoice-icon {
  background-image: url('/assets/images/icons/invoice-white@3x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 90px;
  display: inline-block;
}

.notify-merge-invoice-icon {
  background-image: url('/assets/images/icons/merge-white@3x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 90px;
  display: inline-block;
}

.notify-add-printer-icon {
  background-image: url('/assets/images/icons/printer-added.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 90px;
  display: inline-block;
}

.notify-test-printer-icon {
  background-image: url('/assets/images/icons/printer-started.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 90px;
  display: inline-block;
}

.notify-invoice-paid-icon {
  background-image: url('/assets/images/icons/checkbox-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 90px;
  display: inline-block;
}

.notify-logs-send-error-icon {
  @include svg-icon('/assets/images/icons/svg/close-circle-outline.svg');
  width: 75px;
  height: 90px;
  background-color: var(--pos-white);
}

.custom-icon-keyboard {
  background: url('/assets/images/icons/keyboard.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 22px;
  display: inline-block;
  margin-top: 3px;
}

.search-icon {
  @include svg-icon('/assets/images/icons/svg/search.svg');
  background-color: var(--pos-light-blue);
  width: 26px;
  height: 26px;
}

.arrow-round-down-icon {
  @include svg-icon('/assets/images/icons/svg/arrow-round-down.svg');
  background-color: var(--pos-light-blue);
  width: 36px;
  height: 36px;
}

.arrow-round-up-icon {
  @include svg-icon('/assets/images/icons/svg/arrow-round-up.svg');
  background-color: var(--pos-light-blue);
  width: 36px;
  height: 36px;
}

.print-outline-icon {
  @include svg-icon('/assets/images/icons/svg/print-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.alert-outline-icon {
  @include svg-icon('/assets/images/icons/svg/alert-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.checkmark-circle-outline-icon {
  @include svg-icon('/assets/images/icons/svg/checkmark-circle-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.information-circle-outline-icon {
  @include svg-icon('/assets/images/icons/svg/information-circle-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.help-circle-outline-icon {
  @include svg-icon('/assets/images/icons/svg/help-circle-outline.svg');
  background-color: var(--pos-light-blue);
  width: 20px;
  height: 20px;
}

.refresh-circle-outline-icon {
  @include svg-icon('/assets/images/icons/svg/refresh-circle-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.timer-outline-icon {
  @include svg-icon('/assets/images/icons/svg/timer-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
}

.cart-outline-icon {
  @include svg-icon('/assets/images/icons/svg/cart-outline.svg');
  background-color: var(--pos-light-blue);
  width: 32px;
  height: 32px;
  margin-bottom: 2px;
}

.tablet-icon {
  @include svg-icon('/assets/images/icons/svg/tablet.svg');
  background-color: var(--pos-light-blue);
  width: 24px;
  height: 24px;
  transform: rotateZ(270deg);
}

.guest-icon {
  @include svg-icon('/assets/images/icons/svg/guest.svg');
  background-color: var(--pos-light-blue);
  width: 30px;
  height: 30px;
  position: relative;
  &::after {
    content: attr(guest-marker);
    position: absolute;
    bottom: 0;
    right: 4px;
    font-size: 12px;
    color: var(--pos-white);
    font-style: normal;
  }
}

.funnel-outline-icon {
  @include svg-icon('/assets/images/icons/svg/funnel-outline.svg');
  background-color: var(--pos-light-blue);
  width: 24px;
  height: 24px;
}

.note-icon {
  @include svg-icon('/assets/images/icons/svg/note.svg');
  background-color: var(--pos-light-blue);
  width: 24px;
  height: 24px;
}

.notifications-outline-icon {
  @include svg-icon('/assets/images/icons/svg/notifications-outline.svg');
  background-color: var(--pos-light-blue);
  width: 24px;
  height: 24px;
}

.close-outline-icon {
  @include svg-icon('/assets/images/icons/svg/close-outline.svg');
  background-color: var(--pos-light-blue);
  width: 24px;
  height: 24px;
}

.receipt-printer-icon {
  @include svg-icon('/assets/images/icons/svg/receipt-printer.svg');
  background-color: var(--pos-white);
  width: 150px;
  height: 150px;
}
